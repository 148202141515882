function Country(name, twoLetterIsoCode, threeLetterIsoCode, domain) {
    this.name = name;
    this.twoLetterIsoCode = twoLetterIsoCode;
    this.threeLetterIsoCode = threeLetterIsoCode;
    this.domain = domain;
    this.url = 'http://' + domain + '.markem-imaje.com';
    this.host = domain + '.markem-imaje.com';

    this.getName = function () {
        return this.Name;
    }

    this.getTwoLetterIsoCode = function () {
        return this.twoLetterIsoCode;
    }

    this.getTwoLetterThreeLetterIsoCode = function () {
        return this.threeLetterIsoCode;
    }

    this.getDomain = function () {
        return this.domain;
    }

    this.toJson = function () {
        return {
            'name': this.name,
            'twoLetterIsoCode': this.twoLetterIsoCode,
            'threeLetterIsoCode': this.threeLetterIsoCode,
            'domain': this.domain,
            'url' : this.url,
            'host' : this.host,
        }
    }
}